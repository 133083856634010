import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Icon } from "@mui/material";

import "../css/Home.css";
import HomeVisual from "../components/HomeVisual.tsx";
import { getSeries, unlockSerieProf } from "../../shared/api/request";
import { Category } from "../../shared/types/Category.ts";
import { Serie } from "../../shared/types/Serie.ts";
import AuthContext from "../../shared/context/TokenContext.tsx";

const Home = () => {
  const [searchParams] = useSearchParams();
  const auth = useContext(AuthContext);

  const [categories, setCategories] = useState<Category[]>([]);
  const [playlist, setPlaylist] = useState<Serie[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msgErr, setMsgErr] = useState("");

  const isOpenFromStripe = useCallback(() => {
    // https://webapp.nabook.co/?utm_source=stripe&utm_campaign=sub&utm_term=success
    // http://localhost:3001/?utm_source=stripe&utm_campaign=sub&utm_term=success
    const utmSource = searchParams.get("utm_source");
    const utmCampaign = searchParams.get("utm_campaign");
    const utmTerm = searchParams.get("utm_term");

    if (
      utmSource &&
      utmSource === "stripe" &&
      utmCampaign &&
      utmCampaign === "sub" &&
      utmTerm &&
      utmTerm === "success" &&
      auth.refresh
    ) {
      // Should reload token because the user is now sub
      auth.refresh();
    }
  }, [searchParams, auth]);

  useEffect(() => {
    const test = async () => {
      setIsLoading(true);
      const data = await getSeries();
      console.log("🚀 ~ file: Home.tsx:19 ~ test ~ data:", data);

      // const cats: Category[] = [];
      // for (let i = 0; i < data.cats.length; i++) {
      //   const c = data.cats[i];
      //   const filteredSeries = c.content.filter((s: Serie) => s.canBeOffline);
      //   c.content = filteredSeries;
      //   if (filteredSeries && filteredSeries.length > 0) cats.push(c);
      // }

      // if (cats && cats.length > 0) setCategories(cats);

      setCategories(data.cats);
      setPlaylist(data.playlist);

      setIsLoading(false);
    };
    test();
    isOpenFromStripe();
  }, [isOpenFromStripe]);

  const onUnlockSerie = async (code: string) => {
    setMsgErr("");
    const res = await unlockSerieProf(code);
    if (!res.isOk) setMsgErr(res.msg);
    else window.location.reload();
  };

  return (
    <div className="Home">
      <div>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: 20,
          }}
        >
          <img
            src={require("../../assets/logo-header.png")}
            style={{
              width: "20vw",
              aspectRatio: 342 / 105,
            }}
            alt=""
          />
        </div>
        <Link to="/profil">
          <div
            style={{
              position: "absolute",
              top: 15,
              right: 30,
            }}
          >
            <Icon sx={{ fontSize: 50 }}>account_circle</Icon>
          </div>
        </Link>
      </div>

      <HomeVisual
        msgErr={msgErr}
        onUnlockSerie={onUnlockSerie}
        isLoading={isLoading}
        cats={categories}
        playlist={playlist}
      />
    </div>
  );
};

export default Home;
