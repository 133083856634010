import React from "react";
import { Serie } from "../types/Serie";
import { Link } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";

type Props = {
  serie: Serie;
};

const VerboseCarousel = (props: Props) => {
  const { serie } = props;
  return (
    <Link to={`/serie/${serie.id}`}>
      <div
        id={`${serie.uid}`}
        style={{
          borderRadius: 10,
          overflow: "hidden",
          backgroundColor: "white",
          width: 325,
          maxWidth: 325,
          minWidth: 325,

          marginLeft: 10,
          marginRight: 10,
          marginTop: 15,
        }}
      >
        <div
          style={{
            width: "100%",
            aspectRatio: 1604 / 740,
          }}
        >
          <img
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#C3C3C3",
            }}
            src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${
              serie.uid
            }/couvertures/${serie.cover || serie.img}`}
            alt=""
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              height: 50,
              aspectRatio: 1,
              margin: 10,
              borderRadius: 10,
            }}
            src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${serie.uid}/couvertures/${serie.logo}`}
            alt=""
          />
          <Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              marginLeft: 10,
              marginRight: 10,
              color: "black",
            }}
          >
            {serie.Seasons[0].summary}
          </Paragraph>
        </div>
      </div>
    </Link>
  );
};

export default VerboseCarousel;
