import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Divider,
  Typography,
  Spin,
  Flex,
} from "antd";
import { InfoCircleFilled, CloseOutlined } from "@ant-design/icons";
import "react-international-phone/style.css";

import "../css/Checkout.css";
import { Coupon, FieldType } from "../screen/Sycapay";
import { PhoneInput } from "react-international-phone";
import { postValidateCoupon } from "../../shared/api/request";

const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

const { Link, Text, Title, Paragraph } = Typography;

type Props = {
  error: null | string;
  isLoading: boolean;

  plan: {
    idPlan: number;
    name: string;
    duration: number;
    unit: string;
    montant: string;
    currency: string;
    currencyCode: string;
  };

  user: {
    mail: string;
    pname?: string;
    name?: string;
  };

  waveInfo: { img: string; url: string } | null;

  onSubmitForm: (value: FieldType) => void;
};

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const isNumeric = (t: string) => {
  const reg = /^-?\d*(\.\d*)?$/;
  return reg.test(t) || t === "" || t === "-";
};

const AddCoupon = (props: {
  onCoupon: (coupon: any) => void;
  currency: string;
  baseAmount: number;
}) => {
  const { onCoupon, currency, baseAmount } = props;

  const [showInput, setShowInput] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState<Coupon | null>(null);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateCoupon = async () => {
    if (coupon.length <= 0) return;

    setIsLoading(true);

    const data = await postValidateCoupon({ coupon });

    if (data.status === "error") {
      setIsLoading(false);
      setError(data.msg);
      return;
    }

    setCouponData(data.coupon);
    onCoupon(data.coupon);

    setIsLoading(false);
  };

  if (couponData !== null)
    return (
      <Row style={{ marginTop: 32 }}>
        <Col flex="auto">
          <Text style={{ color: "white", fontSize: 14 }}>Code promo</Text>
        </Col>
        <Col flex="none">
          <Flex vertical>
            <Button
              type="text"
              style={{ backgroundColor: "#2B1A59", color: "white" }}
              onClick={() => {
                setCouponData(null);
                onCoupon(null);
              }}
              icon={<CloseOutlined />}
            >
              {coupon.toUpperCase()}
            </Button>
            <Text style={{ color: "#888598", fontSize: 14 }}>
              -
              {couponData.amountDiscount
                ? couponData.amountDiscount
                : couponData.perDiscount * baseAmount}{" "}
              {currency}
            </Text>
          </Flex>
        </Col>
      </Row>
    );

  if (!showInput)
    return (
      <Row style={{ marginTop: 32 }}>
        <Col flex="auto">
          <Text style={{ color: "white", fontSize: 14 }}>Code promo</Text>
        </Col>
        <Col flex="none">
          <Button
            type="text"
            style={{ backgroundColor: "#2B1A59", color: "white" }}
            onClick={() => setShowInput(true)}
          >
            Ajouter un code promo
          </Button>
        </Col>
      </Row>
    );

  if (showInput)
    return (
      <Row style={{ marginTop: 32 }}>
        <Col flex="auto">
          <Text style={{ color: "#888598", fontSize: 14 }}>Code promo</Text>
        </Col>
        <Col flex="auto">
          <Flex vertical>
            <Space.Compact>
              <Input
                disabled={isLoading}
                onChange={(e) => {
                  setError("");
                  setCoupon(e.target.value.toUpperCase());
                }}
              />
              <Button
                loading={isLoading}
                type="text"
                style={{ backgroundColor: "white", color: "#120D35" }}
                onClick={() => validateCoupon()}
              >
                Valider
              </Button>
            </Space.Compact>
            {error.length > 0 ? <Text type="danger">{error}</Text> : null}
          </Flex>
        </Col>
      </Row>
    );

  return null;
};

const SycapayVisual = (props: Props) => {
  const { error, isLoading, plan, user, waveInfo, onSubmitForm } = props;

  const [form] = Form.useForm();

  const [operateur, setOperateur] = useState("OrangeCI");
  const [otp, setOTP] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("07");
  const [amount] = useState(parseInt(plan.montant, 10));
  const [coupon, setCoupon] = useState(undefined);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const isValid = isPhoneValid(phoneNumber);

  useEffect(() => {
    switch (operateur) {
      case "MtnCI":
        setPhoneNumber("05");
        break;
      case "MoovCI":
        setPhoneNumber("01");
        break;
      case "WaveSN":
        setPhoneNumber("01");
        break;
      default:
        setPhoneNumber("07");
        break;
    }
  }, [operateur, form]);

  const onOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    if (isNumeric(inputValue)) setOTP(inputValue);
  };

  const submit = (values: FieldType) => {
    if (isValid)
      onSubmitForm({
        ...values,
        telephone: phoneNumber,
        coupon,
      });
  };

  const onCoupon = (coupon: any) => {
    setCoupon(coupon);
    if (coupon === null) setDiscountedAmount(0);
    else
      setDiscountedAmount(
        coupon.amountDiscount
          ? amount - coupon.amountDiscount
          : amount - coupon.perDiscount * amount
      );
  };

  return (
    <Row
      style={{
        height: "100vh",
        flex: 1,
        backgroundColor: "#120d35",
      }}
    >
      <Spin
        tip={
          isLoading && waveInfo ? (
            <Flex vertical align="center" style={{ marginTop: 12 }}>
              <div
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 8,
                  overflow: "hidden",
                }}
              >
                <img src={waveInfo?.img} alt="" />
              </div>
              <Flex vertical gap={"small"}>
                <p
                  style={{
                    backgroundColor: "rgba(0,0,0,0.75)",
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 4,
                    paddingBottom: 4,
                    borderRadius: 8,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                >
                  Scannez le QRCode pour compléter la transaction ou
                </p>
                <Button href={waveInfo.url} target="_blank" rel="noreferrer">
                  Cliquez ici
                </Button>
              </Flex>
            </Flex>
          ) : null
        }
        spinning={isLoading}
        fullscreen
      />

      <Col md={12} lg={12} xl={12} sm={12} xs={24} style={{ padding: 32 }}>
        <img
          alt=""
          src={require("../../assets/logo192.png")}
          style={{ width: "10%", aspectRatio: 1 }}
        />

        <Title style={{ color: "white" }} level={2}>
          Paiement
        </Title>

        <Title style={{ color: "white" }} level={5}>
          La recharge {plan.name} Nabook
        </Title>
        <Paragraph style={{ color: "#888598" }}>
          Débloquez l'accès à l'application mobile pendant {plan.name}.
        </Paragraph>
        <AddCoupon
          onCoupon={onCoupon}
          currency={plan.currency}
          baseAmount={amount}
        />
        <Divider plain style={{ backgroundColor: "#888598" }}></Divider>
        <Row style={{ marginTop: 24 }}>
          <Col flex="auto">
            <Text style={{ color: "white", fontSize: 20 }}>MONTANT</Text>
          </Col>
          <Col span={10} style={{ textAlign: "right" }}>
            <Text
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {`${discountedAmount > 0 ? discountedAmount : amount} ${
                plan.currency
              }`}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
        md={12}
        lg={12}
        xl={12}
        sm={12}
        xs={24}
      >
        <Form
          form={form}
          style={{
            minWidth: "100%",
            minHeight: "100%",
            backgroundColor: "white",
            padding: 32,
          }}
          layout="vertical"
          onFinish={submit}
          autoComplete="off"
          initialValues={{
            mail: user.mail,
            pname: user.pname,
            name: user.name,
            operateur,
            indicatif: "07",
            montant: plan.montant,
            currency: plan.currencyCode,
            idPlan: plan.idPlan,
          }}
        >
          <Divider orientation="left">Votre compte</Divider>

          <Form.Item
            name="mail"
            rules={[{ required: true, message: "Mail requis" }]}
          >
            <Input placeholder="Email" disabled />
          </Form.Item>

          <Space>
            <Form.Item name="pname">
              <Input placeholder="Prénom" maxLength={255} />
            </Form.Item>
            <Form.Item name="name">
              <Input placeholder="Nom" maxLength={255} />
            </Form.Item>
          </Space>

          <Divider orientation="left">Informations de paiement</Divider>

          <Space direction="vertical" size={"small"}>
            <Form.Item
              name="operateur"
              label="Opérateur"
              style={{ marginBottom: 8 }}
            >
              <Select onChange={setOperateur}>
                <Select.Option value="OrangeCI">Orange Money</Select.Option>
                <Select.Option value="MtnCI">MTN Money</Select.Option>
                <Select.Option value="MoovCI">Moov Money</Select.Option>
                <Select.Option value="WaveSN">Wave</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Numéro de téléphone"
              rules={[
                { required: true, message: "Numéro de téléphone requis" },
              ]}
            >
              <PhoneInput
                disableDialCodeAndPrefix
                showDisabledDialCodeAndPrefix
                defaultCountry="ci"
                value={phoneNumber}
                countries={[["Côte d'Ivoire", "ci", "225", ".. .. .. .. .."]]}
                onChange={(phone) => setPhoneNumber(phone)}
              />
              {!isValid && phoneNumber.length > 3 && (
                <div style={{ color: "red" }}>Numéro de téléphone invalide</div>
              )}
            </Form.Item>

            {operateur === "OrangeCI" ? (
              <Form.Item label="Code de paiement">
                <Row
                  align={"middle"}
                  style={{
                    backgroundColor: "#F1F1F1",
                    padding: 10,
                    borderRadius: 4,
                    marginBottom: 12,
                  }}
                >
                  <Col
                    span={2}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      paddingRight: 10,
                    }}
                  >
                    <InfoCircleFilled />
                  </Col>
                  <Col span={22}>
                    Générez un code de paiement depuis l'application{" "}
                    <Text strong>Orange Money</Text> ou composez le{" "}
                    <Link href="tel:#144*82#" target="_blank">
                      #144*82#
                    </Link>
                  </Col>
                </Row>

                <Form.Item
                  name="otp"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "Un OTP est requis avec Orange Money",
                    },
                  ]}
                >
                  <Input
                    placeholder="7908"
                    maxLength={4}
                    onChange={onOTPChange}
                    value={otp}
                  />
                </Form.Item>
              </Form.Item>
            ) : null}
          </Space>

          <Form.Item name="currency" hidden noStyle>
            <Input />
          </Form.Item>
          <Form.Item name="montant" hidden noStyle>
            <Input />
          </Form.Item>
          <Form.Item name="idPlan" hidden noStyle>
            <Input />
          </Form.Item>

          <Form.Item hidden={error === null}>
            <Text type="danger">{error}</Text>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading || !isValid}
            >
              Confirmer
            </Button>
          </Form.Item>
          <Paragraph style={{ color: "#C1C1C1" }}>
            En confirmant vous acceptez nos Conditions Générales de Vente. Ce
            produit est vendu par Pegase Innovation.
          </Paragraph>
          <Link href="https://www.nabook.co/recharges">
            Annuler la transaction
          </Link>
        </Form>
      </Col>
    </Row>
  );
};

export default SycapayVisual;
