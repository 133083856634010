import React from "react";
import { Button, Card, Flex, Typography } from "antd";

const { Title, Text } = Typography;

const SubscribePage = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#120d35",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Title
        level={3}
        style={{
          color: "white",
          textAlign: "center",
          marginBottom: 32,
          marginTop: 32,
        }}
      >
        7 jours gratuits pour commencer à lire sur Nabook
      </Title>
      <Flex wrap="wrap" gap="large" align="center" justify="center">
        <Card
          hoverable
          style={{
            width: 290,
            backgroundColor: "#2B1A59",
            padding: 10,
            border: 0,
          }}
          cover={
            <img
              style={{ borderRadius: 10 }}
              alt="annuel"
              src={require("../../assets/mensuel.jpg")}
            />
          }
        >
          <Flex vertical gap={"large"}>
            <Flex vertical>
              <Text
                style={{ color: "white", fontWeight: "bold", fontSize: 20 }}
              >
                5,99€ / mois
              </Text>
              <Text style={{ color: "white", fontSize: 13 }}>
                Prélèvement mensuel jusqu'à résiliation
              </Text>
            </Flex>
            <Button
              style={{
                display: "block",
                backgroundColor: "#3E276F",
                color: "white",
                fontWeight: "bold",
                border: 0,
              }}
              onClick={() => {
                window.location.replace(
                  "https://buy.stripe.com/5kAaFa3yU2OEdqwcMN"
                );
              }}
            >
              LANCER L'ESSAI GRATUIT
            </Button>
          </Flex>
        </Card>
        <Card
          hoverable
          style={{
            width: 290,
            backgroundColor: "#FEDD03",
            padding: 10,
            border: 0,
          }}
          cover={
            <img
              style={{ borderRadius: 10 }}
              alt="annuel"
              src={require("../../assets/annuel.jpg")}
            />
          }
        >
          <Flex vertical gap={"large"}>
            <Flex vertical>
              <Text
                style={{ color: "black", fontWeight: "bold", fontSize: 20 }}
              >
                49,99€ / an
              </Text>
              <Text style={{ color: "black", fontSize: 13 }}>
                Prélèvement annuel jusqu'à résiliation
              </Text>
            </Flex>
            <Flex vertical align="center">
              <Button
                style={{
                  display: "block",
                  backgroundColor: "#3E276F",
                  color: "white",
                  fontWeight: "bold",
                  border: 0,
                }}
                onClick={() => {
                  window.location.replace(
                    "https://buy.stripe.com/fZeeVqd9ubla86c4gK"
                  );
                }}
              >
                LANCER L'ESSAI GRATUIT
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Flex>
        <Text style={{ color: "white", fontSize: 12, marginTop: 24 }}>
          Vous pouvez annuler votre abonnement à tout moment
        </Text>
      </Flex>
    </div>
  );
};

export default SubscribePage;
