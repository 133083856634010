const DicoButton = (props: any) => {
  const { definition, id, dico, params, clickDico, children } = props;

  if (definition && definition.length > 0)
    return (
      <button
        style={{
          backgroundColor: "transparent",
          fontWeight: "bold",
          textDecoration: "underline",
          outline: 0,
          border: 0,
          padding: 0,
          color: params?.color || "white",
          fontFamily: params?.isDYS
            ? "OpenDyslexic-Regular"
            : "Poppins-Regular",
          fontSize: params?.fontSize || 16,
        }}
        onClick={() => {
          clickDico({
            id: id,
            word: dico,
            definition: definition,
          });
        }}
      >
        {children}
      </button>
    );
  else return <>{children}</>;
};

export default DicoButton;
