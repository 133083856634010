import { createContext, useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import * as jose from "jose";

import { refreshToken } from "../api/request";

interface AuthProps {
  user: string | null;

  refresh?: () => void;
  logout?: () => void;
  isBeneylu?: () => boolean;
}

const AuthContext = createContext<AuthProps>({
  user: null,
});

const BASE_URL_AUTH =
  process.env.NODE_ENV === "production"
    ? "https://auth.nabook.co/signin?redirect_to=https://webapp.nabook.co/"
    : "http://localhost:3003/signin?redirect_to=http://localhost:3001";

export const AuthContextProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<string | null>(null);

  const logout = useCallback(() => {
    Cookies.remove("user");
    window.location.replace(BASE_URL_AUTH);
  }, []);

  const refresh = async () => {
    const userProfile = Cookies.get("user");
    if (userProfile) {
      const t = await refreshToken();
      if (!t || !t.isOk) logout();
    }
  };

  const isBeneylu = () => {
    const userProfile = Cookies.get("user");
    if (!userProfile) return false;
    const decoded: { type?: string } = jose.decodeJwt(userProfile);
    console.log(
      "🚀 ~ file: TokenContext.tsx:44 ~ isBeneylu ~ decoded:",
      decoded
    );
    if (decoded.type && decoded.type === "beneylu") return true;
    return false;
  };

  useEffect(() => {
    const userProfile = Cookies.get("user");
    if (userProfile) setUser(userProfile);
    else logout();
  }, [logout]);

  return (
    <>
      <AuthContext.Provider value={{ user, refresh, logout, isBeneylu }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
