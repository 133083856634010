import React from "react";

import "../../shared/css/basicStyles.css";

import { Collection as CollectionType } from "../../shared/types/Collection";
import Close from "../../shared/components/Close";
import Loader from "../../shared/components/Loader";
import { Flex } from "antd";
import Collection from "../../shared/cover/Collection";

type Props = {
  data: CollectionType[] | undefined;
  isLoading: boolean;
};

const AllCollectionVisual = (props: Props) => {
  const { data, isLoading } = props;

  if (isLoading) return <Loader />;

  if (data && data.length > 0)
    return (
      <div style={{ marginTop: 82, marginBottom: 100 }}>
        <h1 className="title">TOUTES LES COLLECTIONS</h1>
        <Flex
          wrap="wrap"
          style={{
            overflow: "scroll",
          }}
        >
          {data.map((c: CollectionType) => {
            return <Collection collection={c} />;
          })}
        </Flex>
        <Close to={".."} />
      </div>
    );

  return (
    <div className="serieContainer">
      <h3>Pas de collection</h3>
      <Close to={".."} />
    </div>
  );
};

export default AllCollectionVisual;
