import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://app.nabook.co"
    : "http://localhost:3000";
const PAY_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://pay.nabook.co"
    : "http://localhost:3004";

// export async function refreshToken(token, profilID) {
//   try {
//     const response = await axios.post(
//       `${BASE_URL}/main/user/refresh`,
//       { profilID },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     // handle the response data
//     // const data = response.data;
//     //...
//   } catch (error) {
//     console.error(error);
//     // handle errors
//   }
// }

export async function getDetailsCollection(uidCollection: string) {
  const token = Cookies.get("user");
  try {
    const res = await axios.get(
      `${BASE_URL}/main/collection/uid/${uidCollection}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log(`[GET_DETAILS_COLLECTION] Il y a eu un problème : ${e}`);
    return null;
  }
}
export async function getAllCollections() {
  const token = Cookies.get("user");
  try {
    const res = await axios.get(`${BASE_URL}/main/collection/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (e) {
    console.log(`[GET_DETAILS_COLLECTION] Il y a eu un problème : ${e}`);
    return null;
  }
}

export async function getSeries() {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(`${BASE_URL}/main/home/v2`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getSerieInfo(idSerie: string | undefined) {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(`${BASE_URL}/main/serie/${idSerie}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function loadEpFile(
  idEp: string | undefined,
  next: boolean,
  infosEp: boolean
) {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(
      `${BASE_URL}/main/serie/episode/${idEp}?next=${next}&infosEp=${infosEp}&v=2`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error: any) {
    console.error(error.response);
    return error.response;
  }
}

export async function putMarkedNode(body: {
  epId: string | undefined;
  nodeId: number;
  lastNode: boolean | undefined;
}) {
  const token = Cookies.get("user");
  try {
    await axios.put(`${BASE_URL}/main/serie/episode/saveMarkedNode`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e: any) {
    console.log("[REQUEST] putMarkedNode", e.message);
  }
}

export async function getProfils() {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(`${BASE_URL}/main/webapp/profil`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function postActivateStripeSub() {
  try {
    const token = Cookies.get("user");
    const response = await axios.post(
      `${BASE_URL}/main/webapp/sub/activate`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function postCancelStripeSub() {
  try {
    const token = Cookies.get("user");
    const response = await axios.post(
      `${BASE_URL}/main/webapp/sub/cancel`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function unlockSerieProf(code: string) {
  try {
    const token = Cookies.get("user");
    const res = await axios.post(
      `${BASE_URL}/main/webapp/reward`,
      {
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getInfoCheckoutSycapay(plan: string) {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(
      `${PAY_BASE_URL}/webapp/payment/pegase/checkout?idPlan=${plan}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.log(
      "🚀 ~ file: request.ts:212 ~ getInfoCheckoutSycapay ~ error:",
      error
    );
    return { error: true, msg: "Erreur interne inconnue" };
  }
}

export async function postCheckoutSycapay(body: any) {
  try {
    const token = Cookies.get("user");
    const response = await axios.post(
      `${PAY_BASE_URL}/webapp/payment/pegase/checkout`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function getStatusSycapay(transactionId: string) {
  try {
    const token = Cookies.get("user");
    const response = await axios.get(
      `${PAY_BASE_URL}/webapp/payment/pegase/status?tid=${transactionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.log(
      "🚀 ~ file: request.ts:212 ~ getInfoCheckoutSycapay ~ error:",
      error
    );
    return { error: true, msg: "Erreur interne inconnue" };
  }
}

export async function postFinishSycapay(body: any) {
  try {
    const token = Cookies.get("user");
    const response = await axios.post(
      `${PAY_BASE_URL}/webapp/payment/pegase/transaction`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function postValidateCoupon(body: any) {
  try {
    const token = Cookies.get("user");
    const response = await axios.post(
      `${PAY_BASE_URL}/webapp/payment/pegase/coupon`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function refreshToken() {
  try {
    const token = Cookies.get("user");
    const response = await axios.post(
      `${BASE_URL}/main/webapp/refresh`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
  }
}
