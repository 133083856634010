import React, { useState } from "react";
import { Avatar, Dropdown, Flex, QRCode, Space, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";

import "../../shared/css/basicStyles.css";
import "../css/PageSerie.css";
import { Serie } from "../../shared/types/Serie";
import { Episode } from "../../shared/types/Episode";
import { Link } from "react-router-dom";
import Close from "../../shared/components/Close";
import Loader from "../../shared/components/Loader";

const feather = require("feather-icons");

const { Text, Title } = Typography;

type Props = {
  serie: Serie | undefined;
  isLoading: boolean;
  isBeneylu: boolean;
};

const PageSerieVisual = (props: Props) => {
  const { serie, isLoading, isBeneylu } = props;

  const [selectedSeason, setSelectedSeason] = useState(0);

  const seasonsIndex = serie?.Seasons.map((sea, index) => {
    return {
      key: index,
      label: `Saison ${index + 1}`,
    };
  });

  const getResume = () => {
    return (
      <div>
        <Title level={2} style={{ color: "white" }}>
          Résumé
        </Title>
        <Text style={{ color: "white" }}>{serie?.Seasons[0].summary}</Text>
      </div>
    );
  };

  const getEpisodeRow = (episode: Episode) => {
    return (
      <div
        style={{
          marginTop: 10,
        }}
        key={episode.id}
      >
        <p className="subtitle">{episode.name}</p>
        <Link
          to={`/reader/${episode.id}/${
            episode.origStartingNode
              ? episode.origStartingNode
              : episode.startingNode
          }`}
          state={{ idSerie: serie?.id, params: { isDys: false, fontSize: 1 } }}
        >
          <div id="containerEpRow">
            <div id="imgContainerEpRow">
              <img
                style={{
                  objectFit: "contain",
                  aspectRatio: 750 / 300,
                  width: "100%",
                }}
                src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${
                  serie?.uid
                }/season-${selectedSeason + 1}/ep-${episode.number}/img/${
                  episode.img
                }`}
                alt=""
              />
              <div className="luckiestGuy" id="epNumber">
                EP{episode.number}
              </div>
            </div>
            <div id="playEp">
              <div
                dangerouslySetInnerHTML={{
                  __html: feather.icons["play-circle"].toSvg({
                    width: 40,
                    height: 40,
                  }),
                }}
              ></div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const getAuthorIllustrator = () => {
    if (
      serie?.Seasons &&
      serie?.Seasons.length > 0 &&
      serie?.Seasons[0].Episodes &&
      serie?.Seasons[0].Episodes.length > 0
    )
      return (
        <p className="bodyText" style={{ marginTop: "50px" }}>
          Écrit par {serie.Seasons[0].Episodes[0].author}
          <br />
          Illustré par {serie.Seasons[0].Episodes[0].illustrator}
        </p>
      );
    return null;
  };

  const getSeason = () => {
    return (
      <Dropdown
        menu={{
          items: seasonsIndex,
          selectable: true,
          defaultSelectedKeys: ["0"],
          onClick: ({ key }) => setSelectedSeason(parseInt(key, 10)),
        }}
      >
        <div
          style={{
            padding: "5px",
            borderRadius: "3px",
            overflow: "hidden",
            border: "1px solid #3E276F",
            backgroundColor: "#2B1A59",
            fontSize: 20,
          }}
        >
          <Space size={"middle"} style={{ padding: 4 }}>
            {`Saison ${selectedSeason + 1}`}
            <DownOutlined />
          </Space>
        </div>
      </Dropdown>
    );
  };
  if (isLoading) return <Loader />;

  if (serie && !serie.canBeOffline)
    return (
      <div className="serieContainer">
        <Flex
          style={{
            width: "100%",
            minHeight: "100vh",
          }}
          vertical
          align="center"
          justify="center"
          gap="large"
        >
          <Flex vertical align="center">
            <h2>Cette série est disponible sur l'application mobile.</h2>
            <h3>Pour la lire, télécharge l'application Nabook.</h3>
          </Flex>
          <Flex
            align="center"
            justify="center"
            onClick={() => {
              window.location.replace(
                "https://nabook.onelink.me/cyS5/r9swckn1"
              );
            }}
          >
            <QRCode
              size={220}
              value="https://nabook.onelink.me/cyS5/r9swckn1"
              bgColor="white"
            />
          </Flex>
          <Flex vertical align="center">
            <p>ou</p>
            <a href="https://5f6b0c5f.sibforms.com/serve/MUIEAOPhDiIQfqR6hciMd7COdRm4KLN-lrmaheNr7gOGFYSEniGMJeYXOdjVLq2l7YJKT04Tor2o2niXW6hRclpavG1stMvbtm4Gcm17NoFGRPZTn2i65JJ6jryefn6TPBd46ObBdMFC4dFM-utsoELiVbyVVTboNf1v9Ds-6YuRcddr3PlcecAn8L9CbivBgaASHKvWWaq2jdng">
              Recevez un lien d'installation par email en cliquant ici
            </a>
          </Flex>
        </Flex>
        <Close to={".."} />
      </div>
    );

  if (serie)
    return (
      <div className="serieContainer">
        <div className="infos">
          <Flex
            gap={"middle"}
            style={{ width: "100%", marginBottom: 24, marginTop: 24 }}
          >
            <Avatar
              size={64}
              shape="square"
              src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${serie.uid}/headers/${serie.imgHeader}`}
            />
            <h2 className="title">{serie.name}</h2>
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            style={{ width: "100%" }}
          >
            {getSeason()}
            <div id="badgeAge">{`Dès ${serie.age} ans`}</div>
          </Flex>
          {getResume()}
          {serie.Seasons[selectedSeason].Episodes?.map((e) => getEpisodeRow(e))}
          {getAuthorIllustrator()}
        </div>
        {isBeneylu ? null : <Close to={".."} />}
      </div>
    );
  return (
    <div className="serieContainer">
      <h3>Pas de série</h3>
      {isBeneylu ? null : <Close to={".."} />}
    </div>
  );
};

export default PageSerieVisual;
