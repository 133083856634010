import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./shared/components/ErrorPage.tsx";

import "./index.css";
import PageSerie from "./series/screen/PageSerie.tsx";
import Reader from "./reader/screen/Reader.tsx";
import { AuthContextProvider } from "./shared/context/TokenContext.tsx";
import Profil from "./user/screen/ProfilScreen.tsx";
import Sycapay, { getSycapayInfo } from "./checkout/screen/Sycapay.tsx";
import SycapaySuccess from "./checkout/screen/SycapaySuccess.tsx";
import PageCollection from "./collection/screen/Collection.tsx";
import AllCollection from "./collection/screen/AllCollection.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/serie/:idSerie",
    element: <PageSerie />,
  },
  {
    path: "/collection",
    element: <AllCollection />,
  },
  {
    path: "/collection/:idCollection",
    element: <PageCollection />,
  },
  {
    path: "/reader/:idEpisode/:nodeId",
    element: <Reader />,
  },
  {
    path: "/profil",
    element: <Profil />,
  },
  {
    path: "/checkout/sycapay",
    element: <Sycapay />,
    loader: getSycapayInfo,
  },
  {
    path: "/checkout/success",
    element: <SycapaySuccess />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <RouterProvider router={router} />
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
