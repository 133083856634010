import { useState, useEffect } from "react";

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    let currentRef;

    if (element.current) {
      observer.observe(element.current);
      currentRef = element.current;
    }

    return () => observer.unobserve(currentRef);
  }, [element, rootMargin]);

  return isVisible;
};

export default useIntersection;
