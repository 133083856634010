import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import "../css/StripeCard.css";
import { Alert, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type Props = {
  clientSecret: string;
  onCancel(): void;
};

export default function CheckoutForm(props: Props) {
  const { clientSecret, onCancel } = props;
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) return;

    if (!elements.getElement(CardElement)) return;

    const card = elements.getElement(CardElement)!;

    const payload = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card,
      },
    });

    if (payload.error) {
      setError(`La mise à jour a échoué ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      setTimeout(() => {
        onCancel();
      }, 5000);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {succeeded ? null : (
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
      )}

      {/* Show any error that happens when processing the payment */}
      {error && (
        <Alert sx={{ marginTop: 2 }} severity="error">
          {" "}
          {error}
        </Alert>
      )}
      {succeeded ? (
        <Alert severity="success">Votre carte a bien été mise à jour</Alert>
      ) : (
        <div style={{ marginTop: 20 }}>
          <LoadingButton
            disabled={processing || disabled || succeeded}
            variant="contained"
            loading={processing}
            color="success"
            type="submit"
            sx={{
              borderColor: "green",
              borderWidth: 1,
              borderStyle: "solid",
              color: "#FFF !important",
            }}
          >
            <span>Valider</span>
          </LoadingButton>
          <Button sx={{ marginLeft: 3 }} variant="outlined" onClick={onCancel}>
            Annuler
          </Button>
        </div>
      )}
    </form>
  );
}
