import React, { useRef } from "react";
import "../../css/Reader.css";
import { BulleV2 } from "../../../shared/types/Bubble";
import BubbleMarkdown from "./BubbleMarkdown";
import useIntersection from "../../../shared/utils/useIntersection";
import { Dico } from "../../../shared/types/Dico";

const tinycolor = require("tinycolor2");
const SIDE_LEFT = "left";
const SIDE_RIGHT = "right";
const CENTER = "center";

type Props = {
  bubble: BulleV2;
  index: number;
  isDYS: boolean;
  fontSize: number;
  viewableItem: (bubble: BulleV2, index: number) => void;
  onDico: (dico: Dico) => void;
};

const BubbleVisual = (props: Props) => {
  const { bubble, index, isDYS, fontSize, viewableItem, onDico } = props;

  const ref = useRef<HTMLDivElement>(null);

  const inViewport = useIntersection(ref, "-200px"); // Trigger if 200px is visible from the element

  let side = CENTER;
  const isNarrator = bubble.speaker.name.toLowerCase() === "narrateur";
  const bgColor = bubble.speaker?.color || "#F3F3F3";
  const shadowColor = tinycolor(bgColor).darken(5).toString();
  const txtColor = !isNarrator ? "#F1F1F1" : "#333333";

  if (!isNarrator) side = index % 2 === 0 ? SIDE_LEFT : SIDE_RIGHT;

  if (inViewport) viewableItem(bubble, index);

  const constructImg = () => {
    const c = bubble.speaker;
    const img = c?.img;
    if (!isNarrator)
      if (c && img)
        return (
          <div
            style={{
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              height: 60,
              maxHeight: 60,
              aspectRatio: 1,
              display: "flex",
            }}
          >
            <img
              style={{
                height: "100%",
                aspectRatio: 1,
                objectFit: "contain",
              }}
              src={`https://newbrand.s3.eu-west-3.amazonaws.com/personnages/${c.img}`}
              alt=""
            />
          </div>
        );
      else if (c)
        return (
          <div
            style={{
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              height: 60,
              maxHeight: 60,
              aspectRatio: 1,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 30,
                aspectRatio: 1,
                backgroundColor: bgColor,
                borderRadius: 100,
              }}
            />
          </div>
        );
    return null;
  };

  const addName = () => {
    const c = bubble.speaker;
    const img = c?.img;
    if (!isNarrator)
      if (side !== CENTER && c && !img) {
        return (
          <div
            style={{
              alignItems: side === SIDE_LEFT ? "flex-start" : "flex-end",
              marginRight: 25,
            }}
          >
            <p
              style={{
                color: shadowColor,
              }}
            >
              {c?.displayName || c?.name}
            </p>
          </div>
        );
      }
    return null;
  };

  return (
    <div
      key={bubble.uid}
      style={{
        marginTop: 24,
        marginBottom: 10,
        marginLeft: 40,
        marginRight: 40,
        display: "flex",
        flexDirection: "row",
        justifyContent: side === SIDE_LEFT ? "flex-start" : "flex-end",
        alignItems: "flex-end",
      }}
      ref={ref}
    >
      {side === SIDE_LEFT ? constructImg() : null}
      <div>
        {addName()}
        <div
          style={{
            backgroundColor: shadowColor,
            paddingBottom: 3,
            borderRadius: 16,
            alignSelf: side === SIDE_RIGHT ? "flex-end" : "flex-start",
          }}
        >
          <div
            style={{
              backgroundColor: bgColor,
              borderRadius: 16,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              color: txtColor,
              fontFamily: isDYS ? "OpenDyslexic-Regular" : "Poppins-Regular",
              fontSize: 16 * fontSize,
              lineHeight: 1.75,
            }}
            className="bubbleText"
          >
            <BubbleMarkdown
              bubble={bubble}
              clickDico={onDico}
              params={{ fontSize: 16 * fontSize, color: txtColor, isDYS }}
            />
          </div>
        </div>
      </div>
      {side === SIDE_RIGHT ? constructImg() : null}
    </div>
  );
};

export default BubbleVisual;
