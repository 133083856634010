import React, { useState } from "react";
import { Alert } from "@mui/material";
import { Button, Flex, QRCode } from "antd";

import DefaultCover from "../../shared/cover/DefaultCover.tsx";
import "../css/Home.css";
import "../../shared/css/basicStyles.css";
import { Category } from "../../shared/types/Category.ts";
import Loader from "../../shared/components/Loader.tsx";
import { Serie } from "../../shared/types/Serie.ts";
import BigCard from "../../shared/cover/BigCard.tsx";
import Card from "../../shared/cover/Card.tsx";
import VerboseCard from "../../shared/cover/VerboseCard.tsx";
import Carousel from "../../shared/cover/Carousel.tsx";
import VerboseCarousel from "../../shared/cover/VerboseCarousel.tsx";
import Highlight from "../../shared/cover/Highlight.tsx";
import Collection from "../../shared/cover/Collection.tsx";
import { Collection as CollectionType } from "../../shared/types/Collection.ts";
import { Link } from "react-router-dom";

type Props = {
  cats: Category[];
  playlist: Serie[] | undefined;
  isLoading: boolean;
  onUnlockSerie: (code: string) => void;
  msgErr: string;
};

const HomeVisual = (props: Props) => {
  const { cats, playlist, isLoading, onUnlockSerie, msgErr } = props;

  const [code, setCode] = useState("");

  const unlockSerie = () => {
    return (
      <Flex
        style={{
          marginTop: 32,
          marginBottom: 32,
        }}
        justify="center"
        wrap="wrap"
        gap="middle"
      >
        <Flex
          style={{
            backgroundColor: "#2B1A59",
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            borderRadius: 10,
          }}
          gap="middle"
        >
          <Flex
            align="center"
            justify="center"
            onClick={() => {
              window.location.replace(
                "https://nabook.onelink.me/cyS5/r9swckn1"
              );
            }}
          >
            <QRCode
              size={120}
              value="https://nabook.onelink.me/cyS5/r9swckn1"
              bgColor="white"
            />
          </Flex>
          <Flex vertical>
            <h3>Profitez de toute l'expérience Nabook</h3>
            <p>Téléchargez l'application</p>
          </Flex>
        </Flex>
        <Flex
          style={{
            backgroundColor: "#2B1A59",
            paddingBottom: 20,
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <h3 style={{ textAlign: "center" }}>Débloquer des séries</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onUnlockSerie(code.toUpperCase());
            }}
          >
            <input
              style={{
                padding: 10,
                borderRadius: 5,
                backgroundColor: "#E8E8E8",
                borderColor: "transparent",
              }}
              value={code.toUpperCase()}
              onChange={(e) => {
                e.preventDefault();
                setCode(`${e.target.value.toUpperCase()}`);
              }}
              maxLength={25}
            />
            {msgErr.length > 0 ? (
              <Alert severity="error">Ce code n'existe pas</Alert>
            ) : null}
            <button type="submit">Valider</button>
          </form>
        </Flex>
      </Flex>
    );
  };

  if (isLoading) return <Loader />;

  if (cats.length > 0)
    return (
      <div className="mainContainer">
        {unlockSerie()}
        {playlist && playlist.length > 0 ? (
          <>
            <h3 className="title">Ta pile à lire</h3>
            <Flex
              wrap="nowrap"
              style={{
                overflow: "scroll",
              }}
            >
              {playlist.map((s: Serie) => {
                return <DefaultCover key={s.uid} serie={s as Serie} />;
              })}
            </Flex>
          </>
        ) : null}
        {cats.map((c) => {
          return (
            <Flex key={c.uid} vertical style={{ marginTop: 20 }}>
              {c.typeContent === "collection" ? (
                <Flex dir="vertical" align="center" justify="space-between">
                  <h3 className="title">{c.name}</h3>
                  <Link
                    style={{
                      color: "white",
                      backgroundColor: "#2B1A59",
                      marginRight: 20,
                      padding: 12,
                      borderRadius: 8,
                    }}
                    to="/collection"
                  >
                    Afficher toutes les collections
                  </Link>
                </Flex>
              ) : (
                <h3 className="title">{c.name}</h3>
              )}
              <Flex
                wrap="nowrap"
                style={{
                  overflow: "scroll",
                }}
              >
                {c.content.map((s: Serie | CollectionType) => {
                  if (c.imgType === 1)
                    return <BigCard key={s.uid} serie={s as Serie} />;
                  if (c.imgType === 2)
                    return <Card key={s.uid} serie={s as Serie} />;
                  if (c.imgType === 3)
                    return <VerboseCard key={s.uid} serie={s as Serie} />;
                  if (c.imgType === 4)
                    return <Carousel key={s.uid} serie={s as Serie} />;
                  if (c.imgType === 5)
                    return <VerboseCarousel key={s.uid} serie={s as Serie} />;
                  if (c.imgType === 6)
                    return <Highlight key={s.uid} serie={s as Serie} />;
                  if (c.imgType === 7)
                    return (
                      <Collection
                        key={s.uid}
                        collection={s as CollectionType}
                      />
                    );

                  return <DefaultCover key={s.uid} serie={s as Serie} />;
                })}
                {c.typeContent === "collection" ? (
                  <div>
                    <Button
                      type="text"
                      style={{
                        backgroundColor: "#2B1A59",
                        color: "white",
                        height: "100%",
                      }}
                    >
                      Afficher toutes les collections
                    </Button>
                  </div>
                ) : null}
              </Flex>
            </Flex>
          );
        })}
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
      }}
    >
      <h2>
        Scannez ce code QR avec votre mobile pour télécharger l'application
      </h2>
      <img
        src={require("../../assets/qr-code.webp")}
        alt=""
        style={{ width: "20vw", marginTop: 10 }}
      />
      <p>ou</p>
      <a href="https://5f6b0c5f.sibforms.com/serve/MUIEAOPhDiIQfqR6hciMd7COdRm4KLN-lrmaheNr7gOGFYSEniGMJeYXOdjVLq2l7YJKT04Tor2o2niXW6hRclpavG1stMvbtm4Gcm17NoFGRPZTn2i65JJ6jryefn6TPBd46ObBdMFC4dFM-utsoELiVbyVVTboNf1v9Ds-6YuRcddr3PlcecAn8L9CbivBgaASHKvWWaq2jdng">
        Recevez un lien d'installation par email en cliquant ici
      </a>
      {unlockSerie()}
    </div>
  );
};

export default HomeVisual;
