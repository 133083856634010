import { Avatar, Button } from "@mui/material";
import { Profil } from "../../shared/types/Profil";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutFormStripe";
import { useState } from "react";
import {
  postActivateStripeSub,
  postCancelStripeSub,
} from "../../shared/api/request";
import { LoadingButton } from "@mui/lab";
import { Flex, QRCode } from "antd";

const stripePromise = loadStripe(
  // "pk_test_NUMrimezt2vKUknYbRFbjcm700fh5hmNeT"
  "pk_live_51EOM36EwDroA4LL2H3Mi5Kz2zmaYMcrSJHDkdQ6rhvZTyx7ItiXA61yNjtVltWuz2mXk7UBEuPjLK307UMfxeX6B00kouODdXQ"
);

type Props = {
  mail: string;
  profils: Profil[];
  clientSecret: string;
  receipt: any;
  logout?: () => void;
};

const ProfilVisual = (props: Props) => {
  const { mail, profils, receipt, clientSecret, logout } = props;

  const [showCardForm, setShowCardForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const activateStripeSub = async () => {
    setIsLoading(true);
    const res = await postActivateStripeSub();
    if (res === "OK") window.location.reload();
  };

  const cancelStripeSub = async () => {
    setIsLoading(true);
    const res = await postCancelStripeSub();
    if (res === "OK") window.location.reload();
  };

  const renderProfil = (p: Profil) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#2B1A59",
          borderRadius: 10,
          padding: 15,
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20,
        }}
      >
        <Avatar
          alt="Avatars"
          src={`https://newbrand.s3.eu-west-3.amazonaws.com/website/profils/${p.avatar}`}
          sx={{ width: 60, height: 60 }}
        />
        <p>{p.name}</p>
      </div>
    );
  };

  const renderSub = () => {
    console.log(receipt);
    switch (receipt.receiptType) {
      default:
      case "none":
        return <p>Vous n'êtes pas abonné(e) à Nabook.</p>;
      case "in-app":
        return (
          <p>
            Abonnement <strong>{receipt.aboType}</strong> qui se renouvelle
            automatiquement le <strong>{receipt.endAt}</strong>.
          </p>
        );
      case "stripe":
        return (
          <>
            {receipt.cancelAtPeriodEnd ? (
              <p>
                Abonnement <strong>{receipt.aboType}</strong> depuis le site web
                qui se termine le <strong>{receipt.endAt}</strong>.
              </p>
            ) : (
              <p>
                Abonnement <strong>{receipt.aboType}</strong> depuis le site web
                qui sera renouvelé automatiquement le{" "}
                <strong>{receipt.endAt}</strong>.
              </p>
            )}

            {showCardForm ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  onCancel={() => {
                    setShowCardForm(false);
                  }}
                  clientSecret={clientSecret}
                />
              </Elements>
            ) : (
              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  // alignItems: "flex-start",
                }}
              >
                <Button
                  variant="contained"
                  href=""
                  onClick={() => {
                    setShowCardForm(true);
                  }}
                >
                  Mettre ma carte à jour
                </Button>
                {receipt.cancelAtPeriodEnd ? (
                  <LoadingButton
                    sx={{ marginLeft: 2 }}
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      activateStripeSub();
                    }}
                    loading={isLoading}
                  >
                    Réactiver mon abonnement
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    sx={{ marginLeft: 2 }}
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      cancelStripeSub();
                    }}
                    loading={isLoading}
                  >
                    Annuler l'abonnement
                  </LoadingButton>
                )}
              </div>
            )}
          </>
        );
      case "gift":
        return (
          <p>
            Vous avez profité d'un code prépayé pour{" "}
            <strong>
              {receipt.duration}{" "}
              {receipt.timeUnit === "m" ? "mois" : "semaines"}
            </strong>{" "}
            d'abonnement. Votre accès à Nabook se termine le{" "}
            <strong>{receipt.endAt}</strong>.
          </p>
        );
    }
  };

  return (
    <div className="userMainContainer">
      <h1 style={{ marginTop: 30 }}>TON PROFIL</h1>
      <div style={{ marginTop: 70 }}>
        <h3>Ton mail</h3>
        <input
          style={{
            padding: 10,
            borderRadius: 5,
            backgroundColor: "#E8E8E8",
            borderColor: "transparent",
          }}
          value={mail}
          disabled
        />

        <Button
          onClick={logout}
          sx={{ backgroundColor: "red", color: "white" }}
        >
          Se déconnecter
        </Button>
      </div>
      <div style={{ marginTop: 30 }}>
        <h3>Profils</h3>
        <div style={{ display: "flex" }}>
          {profils.map((p) => renderProfil(p))}
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <h3>Abonnement</h3>
        {renderSub()}
      </div>
      <div style={{ marginTop: 50, display: "flex", flexDirection: "column" }}>
        <h3>Un problème ?</h3>
        <Button
          sx={{ backgroundColor: "#FFCC00", color: "black" }}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          href="https://www.nabook.co/faq-old/"
        >
          Consulter la FAQ
        </Button>
        <Button
          sx={{ marginTop: 2, borderColor: "gray", color: "gray" }}
          variant="outlined"
          href="mailto:hello@nabook.co"
        >
          Nous contacter
        </Button>
      </div>

      <Flex
        style={{ marginTop: 32, marginBottom: 120 }}
        vertical
        align="center"
        justify="center"
        gap="large"
      >
        <Flex vertical align="center">
          <h2>
            Scannez ce code QR avec votre mobile pour télécharger l'application
            mobile
          </h2>
        </Flex>
        <Flex
          align="center"
          justify="center"
          onClick={() => {
            window.location.replace("https://nabook.onelink.me/cyS5/r9swckn1");
          }}
        >
          <QRCode
            size={220}
            value="https://nabook.onelink.me/cyS5/r9swckn1"
            bgColor="white"
          />
        </Flex>
        <Flex vertical align="center">
          <p>ou</p>
          <a href="https://5f6b0c5f.sibforms.com/serve/MUIEAOPhDiIQfqR6hciMd7COdRm4KLN-lrmaheNr7gOGFYSEniGMJeYXOdjVLq2l7YJKT04Tor2o2niXW6hRclpavG1stMvbtm4Gcm17NoFGRPZTn2i65JJ6jryefn6TPBd46ObBdMFC4dFM-utsoELiVbyVVTboNf1v9Ds-6YuRcddr3PlcecAn8L9CbivBgaASHKvWWaq2jdng">
            Recevez un lien d'installation par email en cliquant ici
          </a>
        </Flex>
      </Flex>
    </div>
  );
};

export default ProfilVisual;
