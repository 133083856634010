import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "../../shared/css/basicStyles.css";

import { getAllCollections } from "../../shared/api/request";
import AllCollectionVisual from "../components/AllCollectionVisual";
import { Collection } from "../../shared/types/Collection";

const AllCollection = () => {
  const { idCollection } = useParams();

  const [data, setData] = useState<Collection[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      const data = await getAllCollections();
      if (data) setData(data.data);
      setIsLoading(false);
    };
    loader();
  }, [idCollection]);

  return (
    <div className="mainContainer">
      <AllCollectionVisual isLoading={isLoading} data={data} />
    </div>
  );
};

export default AllCollection;
