import { Link } from "react-router-dom";

const FooterEndSerie = (props: { path: string }) => {
  return (
    <div
      style={{
        justifyContent: "center",
      }}
    >
      <Link
        to={props.path}
        style={{
          justifyContent: "center",
          marginTop: "0.66rem",
          marginBottom: "0.66rem",
          marginLeft: "1rem",
          marginRight: "1rem",
          flexDirection: "row",
          textDecoration: "none",
        }}
      >
        <div
          style={{
            backgroundColor: "#FF6600",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
            marginTop: 24,
            marginBottom: 10,
            marginLeft: 40,
            marginRight: 40,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 10,
            flexDirection: "row",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontFamily: "Roboto",
              color: "white",
              textAlign: "center",
            }}
          >
            Tu as terminé la série !
          </p>
        </div>
      </Link>
    </div>
  );
};

export default FooterEndSerie;
