import React, { useContext, useEffect, useState } from "react";

import "../../shared/css/basicStyles.css";
import { useLocation, useParams } from "react-router-dom";
import ReaderVisual from "../components/ReaderVisual";
import { loadEpFile, putMarkedNode } from "../../shared/api/request";
import { BulleV2 } from "../../shared/types/Bubble";
import { Character } from "../../shared/types/Character";
import { Episode } from "../../shared/types/Episode";
import SubscribePage from "../../shared/components/SubscribePage";
import Close from "../../shared/components/Close";
import AuthContext from "../../shared/context/TokenContext";

const Reader = () => {
  const ctx = useContext(AuthContext);
  const { idEpisode, nodeId } = useParams();
  let { state } = useLocation();

  const [isBeneylu, setIsBeneylu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [bubbles, setBubbles] = useState<BulleV2[]>();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [nextEp, setNextEp] = useState<Episode | undefined>();

  const [visibleBubble, setVisibleBubble] = useState<number>(-1);

  const saveLastVisibleBubble = async () => {
    if (visibleBubble >= 0) {
      await putMarkedNode({
        epId: idEpisode,
        nodeId: visibleBubble,
        lastNode: bubbles && bubbles?.length - 1 <= visibleBubble,
      });
    }
  };

  useEffect(() => {
    const saveIndexBubble = setInterval(async () => {
      await saveLastVisibleBubble();
    }, 10000);

    return () => {
      clearTimeout(saveIndexBubble);
    };
  });

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      if (ctx.isBeneylu) setIsBeneylu(ctx.isBeneylu());
      const data = await loadEpFile(idEpisode, true, true);

      if (data.bubbles) {
        setBubbles(
          data.bubbles
          // .map((b: Bubble) => {
          //   if (b.text && b.text.length > 0)
          //     b.text = b.text.replaceAll("<", "").replaceAll(">", "");
          //   return b;
          // })
        );
        if (data.infosEpToSend) setCharacters(data.infosEpToSend);
        setNextEp(data.nextEpToSend);
      } else {
        if (data.status === 401)
          setMsgError("Vous devez être abonné pour accéder à cet épisode.");
        else setMsgError("Erreur inconnu");
      }
      setIsLoading(false);
    };
    loader();
  }, [ctx, idEpisode, nodeId]);

  if (msgError && msgError.length > 0)
    return (
      <div className="mainContainer">
        <SubscribePage />
        <Close to={".."} />
      </div>
    );

  return (
    <div className="mainContainer">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ReaderVisual
          idSerie={state.idSerie}
          bubbles={bubbles}
          characters={characters}
          nextEp={nextEp}
          isLoading={isLoading}
          setViewableItem={(_bubble: BulleV2, index: number) => {
            setVisibleBubble(index);
          }}
          params={state.params}
          isBeneylu={isBeneylu}
        />
      </div>
    </div>
  );
};

export default Reader;
