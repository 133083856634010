import React from "react";

import "../../shared/css/basicStyles.css";

import { Collection } from "../../shared/types/Collection";
import Close from "../../shared/components/Close";
import Loader from "../../shared/components/Loader";
import { Serie } from "../../shared/types/Serie";
import DefaultCover from "../../shared/cover/DefaultCover";
import { Avatar, Flex } from "antd";

type Props = {
  data: { collection: Collection; series: Serie[] } | undefined;
  isLoading: boolean;
};

const PageCollectionVisual = (props: Props) => {
  const { data, isLoading } = props;

  if (isLoading) return <Loader />;

  if (data && data.series && data.series.length > 0)
    return (
      <div style={{ marginTop: 82 }}>
        <Flex
          gap={"middle"}
          style={{ width: "100%", marginBottom: 24, marginTop: 24 }}
        >
          <Avatar
            size={64}
            shape="square"
            src={`https://newbrand.s3.eu-west-3.amazonaws.com/collections/${data.collection.uid}/${data.collection.imgCollection}`}
          />
          <h2 className="title">{data.collection.name.toUpperCase()}</h2>
        </Flex>
        <h3 className="title">Toutes les séries</h3>
        <Flex
          wrap="wrap"
          style={{
            overflow: "scroll",
          }}
        >
          {data.series.map((s) => {
            return <DefaultCover key={s.uid} serie={s} />;
          })}
        </Flex>
        <Close to={".."} />
      </div>
    );

  return (
    <div className="serieContainer">
      <h3>Pas de collection</h3>
      <Close to={".."} />
    </div>
  );
};

export default PageCollectionVisual;
