import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "../../shared/css/basicStyles.css";

import { getDetailsCollection } from "../../shared/api/request";
import PageCollectionVisual from "../components/CollectionVisual";
import { Serie } from "../../shared/types/Serie";
import { Collection } from "../../shared/types/Collection";

const PageCollection = () => {
  const { idCollection } = useParams();

  const [data, setData] = useState<
    { series: Serie[]; collection: Collection } | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      if (idCollection && idCollection.length > 0) {
        setIsLoading(true);
        const data = await getDetailsCollection(idCollection);
        if (data) setData(data);
        setIsLoading(false);
      }
    };
    loader();
  }, [idCollection]);

  return (
    <div className="mainContainer">
      <PageCollectionVisual isLoading={isLoading} data={data} />
    </div>
  );
};

export default PageCollection;
