import React from "react";
import { Link } from "react-router-dom";

import { Collection as CollectionType } from "../types/Collection";

type Props = {
  collection: CollectionType;
};

const Collection = (props: Props) => {
  const { collection } = props;

  return (
    <Link to={`/collection/${collection.uid}`}>
      <div
        style={{
          borderRadius: "1vw",
          overflow: "hidden",
          height: "18vw",
          aspectRatio: 1000 / 390,
          marginLeft: 10,
          marginRight: 10,
          marginTop: 15,
        }}
      >
        <img
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#C3C3C3",
          }}
          src={`https://newbrand.s3.eu-west-3.amazonaws.com/collections/${collection.uid}/${collection.imgCollection}`}
          alt=""
        />
      </div>
    </Link>
  );
};

export default Collection;
