import React, { ChangeEvent, useState } from "react";
import "../../shared/css/basicStyles.css";
import "../css/Reader.css";
import { Slider, Switch, Typography } from "@mui/material";

type Props = {
  handleSwitch(_event: ChangeEvent<HTMLInputElement>, checked: boolean): void;
  handleSlider(_event: Event, value: number | number[]): void;
  params: { isDys: boolean; fontSize: number };
};

const Navbar = (props: Props) => {
  const { handleSlider, handleSwitch, params } = props;

  const [sliderValue, setSliderValue] = useState(params.fontSize);

  return (
    <div style={{ marginTop: 24 }} id="navbar">
      <Typography gutterBottom>Activer la police DYS</Typography>
      <Switch defaultChecked={params.isDys} onChange={handleSwitch} />
      <Typography style={{ marginTop: 20 }} gutterBottom>
        Changer la taille du texte
      </Typography>
      <Slider
        min={1}
        max={2}
        value={sliderValue}
        step={0.1}
        marks
        onChange={(event: Event, value: number | number[]) => {
          setSliderValue(Number(value));
          handleSlider(event, value);
        }}
        defaultValue={30}
      />
    </div>
  );
};

export default Navbar;
