import React from "react";
import { Serie } from "../types/Serie";
import { Link } from "react-router-dom";

type Props = {
  serie: Serie;
};

const DefaultCover = (props: Props) => {
  const { serie } = props;
  return (
    <Link to={`/serie/${serie.id}`}>
      <div
        style={{
          height: "18vw",
          aspectRatio: 777 / 1035,
          borderRadius: "1vw",
          overflow: "hidden",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 15,
        }}
      >
        <img
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#C3C3C3",
          }}
          src={`https://newbrand.s3.eu-west-3.amazonaws.com/series/${
            serie.uid
          }/couvertures/${serie.cover || serie.img}`}
          alt=""
        />
      </div>
    </Link>
  );
};

export default DefaultCover;
