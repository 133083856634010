import React from "react";
import BubbleMarkdown from "./BubbleMarkdown";
import { BulleV2 } from "../../../shared/types/Bubble";

type Props = {
  bubble: BulleV2;
};

const BubbleImage = (props: Props) => {
  const { bubble } = props;

  return (
    <div
      key={bubble.uid}
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "center",
        maxWidth: "100%",
      }}
    >
      <BubbleMarkdown bubble={bubble} />
    </div>
  );
};

export default BubbleImage;
