import { useContext, useEffect, useState } from "react";
import ProfilVisual from "../components/ProfilVisual";
import { getProfils } from "../../shared/api/request";

import "../css/User.css";
import { Profil } from "../../shared/types/Profil";
import Close from "../../shared/components/Close";
import AuthContext from "../../shared/context/TokenContext";

const ProfilScreen = () => {
  const auth = useContext(AuthContext);

  const [mail, setMail] = useState("");
  const [profils, setProfils] = useState<Profil[]>([]);
  const [clientSecret, setClientSecret] = useState("");
  const [receipt, setReceipt] = useState({});

  useEffect(() => {
    const loadInfos = async () => {
      const infos = await getProfils();
      setMail(infos.mail);
      setProfils(infos.profils);
      setClientSecret(infos.clientSecret);
      setReceipt(infos.receipt);
    };
    loadInfos();
  }, []);

  return (
    <div className="user">
      <ProfilVisual
        mail={mail}
        profils={profils}
        clientSecret={clientSecret}
        receipt={receipt}
        logout={auth.logout}
      />
      <Close to=".." />
    </div>
  );
};

export default ProfilScreen;
