import React, { useContext, useEffect, useState } from "react";
import "../../shared/css/basicStyles.css";
import { useParams } from "react-router-dom";
import { getSerieInfo } from "../../shared/api/request";
import { Serie } from "../../shared/types/Serie";
import PageSerieVisual from "../components/PageSerieVisual";
import AuthContext from "../../shared/context/TokenContext";

const PageSerie = () => {
  const ctx = useContext(AuthContext);
  const { idSerie } = useParams();

  const [data, setData] = useState<Serie>();
  const [isLoading, setIsLoading] = useState(false);
  const [isBeneylu, setIsBeneylu] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      if (ctx.isBeneylu) setIsBeneylu(ctx.isBeneylu());
      const data = await getSerieInfo(idSerie);
      if (data) setData(data);
      setIsLoading(false);
    };
    loader();
  }, [ctx, idSerie]);
  return (
    <div className="mainContainer">
      <PageSerieVisual
        isLoading={isLoading}
        isBeneylu={isBeneylu}
        serie={data}
      />
    </div>
  );
};

export default PageSerie;
