import { useState } from "react";
import {
  getInfoCheckoutSycapay,
  getStatusSycapay,
  postCheckoutSycapay,
  postFinishSycapay,
} from "../../shared/api/request";
import SycapayVisual from "../components/SycapayVisual";
import { Navigate, useLoaderData } from "react-router-dom";
import { Button, Flex } from "antd";

export type Coupon = {
  name: string;
  perDiscount: number;
  amountDiscount: number;
};

export type FieldType = {
  mail: string;
  name: string;
  pname: string;
  operateur: string;
  telephone: string;
  indicatif: string;
  montant: string;
  currency: string;
  idPlan: number;
  otp?: string;
  coupon?: Coupon;
};

export const getSycapayInfo = async ({ request }: { request: any }) => {
  const plan = new URL(request.url).searchParams.get("plan");

  const details = await getInfoCheckoutSycapay(plan || "1");

  return details;
};

const Error = (props: { msg: string | undefined }) => {
  const { msg } = props;
  return (
    <Flex
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#120d35",
      }}
      vertical
      gap={"large"}
    >
      <img
        src={require("../../assets/logo-header.png")}
        style={{
          width: "20vw",
          aspectRatio: 342 / 105,
          marginTop: 32,
        }}
        alt=""
      />
      <Flex
        style={{
          flexGrow: 1,
          marginBottom: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
        vertical
      >
        <p style={{ color: "white", fontSize: 16 }}>
          Une erreur s'est produite, veuillez réessayer dans un instant.
        </p>
        <p style={{ color: "white", fontSize: 16, marginBottom: 22 }}>
          Détail de l'erreur : {msg}
        </p>
        <Button href="/">Revenir à l'accueil</Button>
      </Flex>
    </Flex>
  );
};

const Sycapay = () => {
  const { error, redirect, msg, user, plan } = useLoaderData() as {
    // on redirect
    error?: boolean;
    redirect?: boolean;
    msg?: string;

    user: {
      // on user can pay
      mail: string;
      name?: string;
      pname?: string;
    };
    plan: {
      idPlan: number;
      name: string;
      duration: number;
      unit: string;
      montant: string;
      currency: string;
      currencyCode: string;
    };
  };

  const [formError, setFormError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [wave, setWave] = useState<{ img: string; url: string } | null>(null);

  const checkStatus = async (
    transactionId: string,
    numCmd: string,
    idPlan: number,
    time: number
  ) => {
    if (time >= 60000) {
      console.log("TIME OUT !!!");
      setIsLoading(false);
      setFormError("La transaction a échoué");
      return;
    }

    setTimeout(async () => {
      try {
        const rJSON = await getStatusSycapay(transactionId);
        // console.log("🚀 ~ file: Sycapay.tsx:126 ~ setTimeout ~ rJSON:", rJSON);

        if (rJSON.status.code === -200)
          checkStatus(transactionId, numCmd, idPlan, time + 2000);
        else {
          const res = await postFinishSycapay({
            idPlan,
            numCmd,
            tid: transactionId,
          });
          if (res.error) {
            setIsLoading(false);
            setFormError(res.msg);
          } else window.location.replace("https://www.nabook.co/achat-valide");
        }
      } catch (e) {
        console.log("🚀 ~ file: Sycapay.tsx:119 ~ setTimeout ~ e:", e);
      }
    }, 2000);
  };

  const onFormSubmit = async (value: FieldType) => {
    setFormError(null);
    setIsLoading(true);
    try {
      const r = await postCheckoutSycapay({
        mail: value.mail,
        name: value.name,
        pname: value.pname,
        operateur: value.operateur,
        telephone: value.telephone,
        otp: value.otp,
        montant: value.montant,
        currency: value.currency,
        idPlan: value.idPlan,
        coupon: value.coupon,
      });
      if (r.error) {
        setFormError(r.msg);
        setIsLoading(false);
      } else {
        // console.log(
        //   "🚀 ~ file: Sycapay.tsx:143 ~ onFormSubmit ~ r.transactionId:",
        //   r
        // );
        checkStatus(r.transactionId, r.numCmd, value.idPlan, 0);
        if (value.operateur === "WaveSN" && r.img)
          setWave({
            img: r.img,
            url: r.url,
          });
      }
      // window.location.replace("https://www.nabook.co/achat-valide");
    } catch (e) {
      setFormError("Erreur inconnue");
    }
  };

  if (redirect)
    return (
      <Navigate
        to="/checkout/success"
        replace
        state={{
          msg: msg,
        }}
      />
    );

  if (error) return <Error msg={msg} />;

  return (
    <div>
      <SycapayVisual
        plan={plan}
        user={user}
        waveInfo={wave}
        error={formError}
        isLoading={isLoading}
        onSubmitForm={onFormSubmit}
      />
    </div>
  );
};

export default Sycapay;
