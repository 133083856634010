import React from "react";
import { Flex, QRCode, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const { Link, Text, Title } = Typography;

const SycapaySuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex
      gap={"large"}
      vertical
      style={{
        backgroundColor: "#120d35",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Flex onClick={() => navigate("/")}>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: 40,
            zIndex: 10,
          }}
        >
          <img
            src={require("../../assets/logo-header.png")}
            style={{
              width: "20vw",
              aspectRatio: 342 / 105,
            }}
            alt=""
          />
        </div>
      </Flex>
      <Flex
        style={{
          marginTop: -100,
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          zIndex: 10,
        }}
        gap={"middle"}
        vertical
      >
        <Title
          style={{ color: "white", padding: 10, textAlign: "center" }}
          level={2}
        >
          {location.state.msg}
        </Title>
        <Text
          style={{
            color: "white",
            fontSize: 16,
            padding: 4,
            textAlign: "center",
          }}
        >
          Pour en profiter, téléchargez l'application en scannant le QR code
        </Text>
        <QRCode
          bgColor="white"
          value="https://nabook.onelink.me/cyS5/r9swckn1"
        />

        <Text
          style={{
            color: "white",
            fontSize: 16,
            padding: 4,
            textAlign: "center",
          }}
        >
          Ou cliquez sur le lien suivant :{" "}
          <Link href="https://nabook.onelink.me/cyS5/r9swckn1" target="_blank">
            Télécharger l'application
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
};

export default SycapaySuccess;
